// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-elements-js": () => import("./../src/pages/Elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fragile-packing-js": () => import("./../src/pages/fragile-packing.js" /* webpackChunkName: "component---src-pages-fragile-packing-js" */),
  "component---src-pages-fullpacking-js": () => import("./../src/pages/fullpacking.js" /* webpackChunkName: "component---src-pages-fullpacking-js" */),
  "component---src-pages-generic-js": () => import("./../src/pages/Generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-get-moving-quote-js": () => import("./../src/pages/get-moving-quote.js" /* webpackChunkName: "component---src-pages-get-moving-quote-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moving-during-coronavirus-js": () => import("./../src/pages/moving-during-coronavirus.js" /* webpackChunkName: "component---src-pages-moving-during-coronavirus-js" */),
  "component---src-pages-piano-moving-js": () => import("./../src/pages/piano-moving.js" /* webpackChunkName: "component---src-pages-piano-moving-js" */),
  "component---src-pages-whatyouget-js": () => import("./../src/pages/whatyouget.js" /* webpackChunkName: "component---src-pages-whatyouget-js" */),
  "component---src-pages-whiteglove-js": () => import("./../src/pages/whiteglove.js" /* webpackChunkName: "component---src-pages-whiteglove-js" */)
}

